.no-calendar-icon .ant-picker-suffix {
    display: none;
}
.ant-table-thead > tr > th {
    background-color: #f5f5f5; 
    color: #000; 
}


.ant-table-tbody > tr > td {
    background-color: #fff; 
    color: #000;
}

@media (max-width: 768px) {
    .main-container {
        padding: 10px;
    }

    .left-container, .right-container {
        width: 100%;
    }

    .field-bg {
        width: 100% !important;
    }

    .dynamic-btn-primary, .dynamic-btn-default {
        width: 100%;
    }

    .table-wrapper {
        height: auto;
        overflow-y: visible;
    }
}