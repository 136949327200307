.consent-screen {
  padding: 15px;
  .img-page-not-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px);
    img {
      width: 30%;
      min-width: 300px;
    }
  }
}

.consent-card-thankyou {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-card {
    margin: 40px;
    height: max-content;
    width: max-content;
    border-radius: 10px;
    box-shadow: 5px 5px 20px #babbbf9a;
    padding: 40px;
    .ant-card-body {
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    p {
      text-align: center;
    }
    @media (max-width: 768px) {
      margin: 10px;
      padding: 10px;
    }
  }
}

.consent-card {
  margin: 40px;
  min-width: 300px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px #babbbf9a;
  padding: 20px;
  .logo-view {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .btn-agree {
    margin-top: 20px;
    margin-left: 20px;
    min-width: 120px;
    border-radius: 6px;
  }
  .ant-form-item-explain-error{
    float: left;
  }
  .ant-checkbox{
    margin-top: 0.3rem;
  }
  .ant-space-item{
    margin-top: 1%;
  }
  @media (max-width: 768px) {
    margin: 0px;
  }
}
