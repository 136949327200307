.borrower-screen {
    padding: 15px;
}

.page-img {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.img-width {
    width: 100%;
}

.terms-conditions {
    overflow: auto;
    // height: 150px;

}

// .applicant-details{
//     // overflow: auto;
//     height: 200px;
// }

.otp-btn {
   // width: 100%;
    margin-top: 20px;
    margin-left:20px;
    // height: 40px;
    width: 190;
    height: 59;
    // top: 2713px;
    border-radius: 6px;

    // border-radius: 20px;
}


.custom-input-number {
    border-radius: 6px !important;
    padding: 4px;
    // border: solid 1px #d9d9d9;
    border: 0.6px solid #B1B1B1;
    &.w-200 {
        width:200px;
    }
  }
.p-content{
    margin-top: 5px;
    font-size: 20px;    
    color: rgba(82,82,86,1);   
}

.details-icons {
    float: right;
    color: black;
    border: none;
  }
  
.mobile-view {
    height: 70vh;
}

.borrower-card {
    width: 60%;
    min-width: 300px;
    height: 100%;
    min-height: fit-content;
    overflow: auto;
    border-radius: 10px;
    box-shadow: 5px 5px 20px #babbbf9a;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 20%);
}

.borrower-details {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 13%;
    transform: translate(-8%, -50%);
}

.confirm-text {
    // color: #20A74E;
    font-size: 20px;
    font-weight: 300;
    background: #0C6EB614;
    color: #0C6EB6;
    margin-top: 20px;
    text-align: center;
}

.expired-text {
    color: #C71C8E;
    margin-top: 10px;
    font-size: 20px;
    font-family: sans-serif !important;
}

.left-text-div-content{
    align-items: center;
    justify-content: space-between;
    display: flex;
    font-size: 18px;
    border-radius: 6px;
    margin-top: 8px;
    color: black;
  }

  .ant-divider-horizontal{
    margin-bottom: 0px !important;
  }

  .custom-link {
    font-size: 20px;
    font-weight: 300;
    color: #0C6EB6;
    text-decoration: underline !important; /* ✅ Enforce underline */
    text-decoration-color: #0C6EB6 !important; /* ✅ Ensure blue underline */
  }
