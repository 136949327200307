@import '~antd/dist/antd.min.css';
@import "shared/style/Variables.scss";
@import 'shared/style/antd/_antd.scss';
@import 'shared/style/main.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $page-background;

  min-height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

#root {
  min-height: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: $font-family, sans-serif !important;
}

.mt-10 {
  margin-top: 10px;
}

.desktop-layout {
  .layout-row {
    overflow: hidden;
    background-color: rgb(248, 247, 251);
  }
}

.box {
  background-color: red;
}

.user-btn-box {
  border-radius: 30px;
  height: 39px;
  cursor: auto;
}

.disable-input {
  .ant-select-disabled,
  .ant-input[disabled] {
    color: #00000070;
    span {
      color: #00000070;
    }
  }
}

.app-btn-border {
  --btnColor: #c71c8e;
  border: 1.4px solid var(--btnColor);
  min-width: 80px;
  gap: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    color: var(--btnColor);
  }
  &:hover, 
  &:focus {
    color: var(--btnColor);
    border-color: var(--btnColor);
  }
  &:hover {
    background-color: #00000011;
  }
  // Don't add svg css here
}

.app-btn-primary-bg {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-inline: 10px;
  background-color: #273896 !important;
  color: white !important;
  min-width: 80px;
  justify-content: center;
  border-radius: 6px;
  svg {
    path {
      stroke: white;
    }
  }
  &:hover {
    background-color: #273896DD !important;
  }
  &:disabled {
    background-color: #273896AA !important;
  }
}

.ant-btn-default {
  background-color: #fff;
 // border-color: #C71C8E;
  color: #C71C8E;
}

.app-btn-secondary-border {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1.4px solid #c71c8e;
  padding-inline: 10px;
  min-width: 80px;
  border-radius: 6px;
  justify-content: center;
  svg {
    path {
      stroke: #c71c8e;
    }
  }
  &:hover, 
  &:focus {
    color: #c71c8e;
    border-color: #c71c8e;
  }
  &:hover {
    background-color: #00000011;
  }
}

.btn-default-bg {
  background-color: #C71C8E;
  border-color: #C71C8E;
  color: #fff;

  &:hover {
    background-color: #C71C8ECC;
    border-color: #C71C8E;
    color: #fff;
  }
}

.ant-layout {
  background: rgb(248, 247, 251) !important;
}

.custom-input {
  border-radius: 11px !important;
  padding: 8px;
  border: 1px solid #d9d9d9 !important;
  height: 39px !important;
  input {
    border: none;
    outline: none;
  }
}

.nav-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 100%;
  padding-bottom: 20px;
}

.nav-panel {
  width: 100%;
  padding-left: 10px;
  padding-bottom: 20px;

  .nav-heading {
    font-size: 16px;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 17px;
    color: #FFF;
    opacity: 0.8;
    font-weight: 390;
  }

  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;

    li {
      .navlink {
        display: flex;
        align-items: center;
        padding: 10px 5px 10px 10px;
        font-style: normal;
        font-weight: 390;
        font-size: 15px;
        margin-bottom: 8px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        outline: none;
        text-decoration: none;

        svg {
          border-bottom: none;
        }

        span {
          font-size: 20px;
          margin-right: 10px;
        }

        &:hover {
          background: #191E39;
        }
      }
    }
  }
}

.custom-checkable {
 //--bgColor:rgba(39, 56, 150, 0.26);
  padding: 4px 10px;
  border: 1px solid rgba(39, 56, 150, 0.26);
  border-radius: 40px;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  margin-left: 0px;
  &:hover,
  &:focus{
    color: rgba(0, 0, 0, 0.85) !important;
    background: none;
  }
}

.custom-checkable.ant-tag-checkable-checked {
  --bgColor:#273896;
  background: var(--bgColor);
  color: #fff !important;
}

.custom-option-list {
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .ant-select-item {
        border-bottom: solid 1px #eaeaea;
      }
    }
  }
}

.custom-auto-compleat {
  .ant-select-selector {
    border-radius: 11px !important;
    height: 39px !important;

    input {
      height: 38px !important;
      background: url('assets/images/search-icon.png') no-repeat right center !important;
      background-size: 20px 20px !important;
      padding-right: 25px !important;
    }

    .ant-select-selection-placeholder {
      line-height: 37px;
    }
  }

  &.selectIcon {
    .ant-select-selector {
      input {
        background: url('assets/images/down-gray.png') no-repeat right center !important;
      }
    }
  }
}

.custom-textarea {
  width: 100%;
  border-radius: 10px;
  min-height: 38px !important;
  textarea {
    border-radius: 10px;
    padding: 8px;
    border: solid 1px #eae9e9;
  }
}


.custom-checkbox {
  margin-bottom: 10px;

  .ant-radio-button-wrapper {
    margin-right: 10px;
    border-radius: 30px;
    cursor: pointer;

    &::before {
      display: none;
    }
  }
}

.custom-select {
  .ant-select-selector {
    height: 39px !important;
    border-radius: 11px !important;
    border: 1px solid #d9d9d9 !important;

    .ant-select-selection-search {
      input {
        height: 39px !important;
      }
    }
  }

  .ant-select-selection-item {
    line-height: 39px !important;
    }

  .ant-select-selection-placeholder {
    line-height: 39px !important;
  }
}

.custom-select-padding-left {
  .ant-select-selector {
    border: 1px solid #d9d9d9 !important;

    .ant-select-selection-search {
      input {
        caret-color: transparent !important;
      }
    }
  }

  .ant-select-selection-item {
    padding-left: 10px !important;
    caret-color: auto !important;
  }

  .ant-select-selection-placeholder {
    padding-left: 10px !important;
  }
}

.custom-select-multiple {
  .ant-select-selector {
    // padding: 8px;
    min-height: 39px !important;
    border-radius: 30px !important;

    .ant-select-selection-search {
      input {
        min-height: 39px !important;
      }
    }

    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        height: 28px;
        border-radius: 30px;
        line-height: 27px !important;

        span.ant-select-selection-item-content {
          font-size: 13px;
          line-height: 25px;
        }

        span.ant-select-selection-item-remove {
          svg {
            font-size: 13px;
          }
        }
      }
    }

    .ant-select-selection-overflow {
      padding: 4px;
    }
  }

  .ant-select-selection-item {
    line-height: 39px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 39px !important;
  }
}

.custom-multiple-select {
  width: 100%;

  .ant-select-selector {
    padding: 3px;
    border-radius: 30px !important;

    .ant-select-selection-item {
      border-radius: 20px;
    }
  }
}

.custom-input-number {
  border-radius: 11px !important;
  padding: 4px;
  border: solid 1px #d9d9d9;
}

.custom-input-number1 {
  border-radius: 11px !important;
  padding: 4px;
  border: 1px solid #d9d9d9 !important;
  border: solid 1px #f1f1f1;
}

.custom-radio {
  --borderColor:#C71C8E;
  .ant-space-align-center {
    .ant-space-item {
      .ant-radio-button-wrapper-checked {
        background-color:var(--borderColor);
        color: #fff;
        border-color: var(--borderColor) !important;
      }

      .ant-radio-button-wrapper {
        box-shadow: none;
        border-radius: 30px;

        &:hover {
          background-color:var(--borderColor);
          border-color: var(--borderColor);
        }
      }

      .ant-radio-button-checked {
        background-color: var(--borderColor);
        border-radius: 30px;
        color: #fff;
      }
      .ant-space-item .ant-radio-button-wrapper-checked {
         background-color: var(--borderColor);
    }
    }
  }
}

.bulk-upload-btn {
  width: 100px;
  height: 40px;
  border-radius: 10px;
}

.ant-steps-item-icon {
  background-color: purple !important;
  color: white !important;
}

.ant-steps-icon {
  // background-color: purple !important;
  color: white !important;
}

.ant-steps-item-process {
  // background-color: purple !important;
  color: purple !important;
}

::selection {
  color: #000;
  background: #cccccc;
}

.fa-solid fa-file-import {
  background-image: linear-gradient(to bottom, #35005e, #4e0069, #660073, #7e007c, #970084) !important;
}

.ant-steps-item-icon {
  fill: none !important;
  width: 20px !important;
  height: 20px !important;
  color: rgb(248, 247, 251) !important;
  // background: white !important;
  // border-color: #C71C8E 2px solid !important;
}

.ant-steps-icon-dot {
  width: 20px !important;
  height: 20px !important;
}

.ant-steps-item-icon {
  width: 20px !important;
  height: 20px !important;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: #C71C8E !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-icon>.ant-steps-icon>.ant-steps-icon-dot::after {
  background-image: url("./assets/images/payouts/check.svg") !important;
  background-repeat: no-repeat !important;
  height: 46px !important;
  width: 71px !important;
  background-position: center;
  content: "";
  margin: 0 auto !important;
  background-size: 14px !important;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: white !important;
  border-style: solid !important;
  border-color: #BFC4E1 !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: transparent !important;
  border-color: transparent !important;
}

.ant-steps-item-icon {
  background: transparent !important;
  border-color: transparent !important;
}

.ant-steps-item-tail::after {
  // display: inline-block;
  width: 100%;
  height: 1px;
  background: rgb(218, 217, 217) !important;
  margin-bottom: 7px !important;
  margin-left: 17px !important;
  border-radius: 1px;
  // transition: background 0.3s;
  content: '';
}


.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
  margin-left: 15px !important;
}

.display-label {
  white-space: nowrap;
}

.cardmain {
  height: 350px;
  width: 100%;
  margin-top: 15px;
  margin-left: -5px;
}

.cardinner {
  height: 350px;
  width: 100%;
  margin-top: 15px;
  background-image: url("../src//assets/images/loader/fileLoader.svg");
  background-repeat: no-repeat;
  background-position: center;
}

// .ant-modal {
//   top: 10% !important;
// }

.add-lead-button {
  background-color: #273896 !important;
  border-color: #273896 !important;
  color: #ffffff !important;
}

.filter-bar-right {
  display: flex;
  justify-content: flex-end;
}

.filter-bar {
  padding: 30px 0px;
  width: 100%;
 // gap: 5px;

  .search-box {
    width: 96% !important;
    //border-radius: 30px !important;  
    height: 45px;
  }

  .filter-btn {
    margin-left: 40px;
    width: 38px !important;
    height: 40px !important;
    border-radius: 10px !important;
    padding-inline: 18px;
    background-color: #fff;
    border:none;
    svg {
      margin-top: 10px;
    }
  }

  .add-button {
    height: 40px;
    width: 160px !important;
    border-radius: 10px;
    float: right;

    svg {
      margin-right: 10px;
      font-size: 13px;
    }
  }

  .bulk-upload-button {
    height: 45px;
    width: auto;
    border-radius: 30px;
    float: right;
    margin-right: 10px;
  }
}

.app-filter-bar {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  .app-filter-bar-left {
    display: flex;
    align-items: center;
    flex: 0.8;
    gap: 10px;
  
    .filter-btn {
      width: 40px !important;
      height: 40px !important;
      border-radius: 10px !important;
      padding-inline: 0px;
      background-color: #fff;
      border:none;
      box-shadow: 1px 1px 4px #eaeaea;
      svg {
        margin-top: 10px;
      }
    }
  }
  
  .app-filter-bar-right {
    display: flex;
    align-items: center;
    gap: 10px;
    .ant-btn {
      height: 38px;
      width: 100px;
      border-radius: 10px;
    }
    .dynamic-btn-primary {
      &.w-170 {
        width: 170px;
      }
    }
  }

  @media only screen and (max-width: 1096px) {
   display: grid;
  }
}

.blueButton {
  --bgColor:#273896;
  background: var(--bgColor);
  color: "white";

}

.pinkButton {
  --borderColor:#C71C8E;
  background: var(--borderColor);
  color: "white";
  
  &:hover,
  &:focus{
    border: 1px solid var(--borderColor);
    color: var(--borderColor);
  }
}

// /* width */
// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
//   cursor: pointer;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: grey;
//   border-radius: 10px;
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.content-screen {
  margin-top: 30px;
  padding: 52px 30px;
}

.dynamic-btn-primary{
  --btnColor: #273896;
  --disableColor:rgba(0, 0, 0, 0.04);
  border-color: var(--btnColor) !important;
  background-color: var(--btnColor) !important;
  color:#fff !important;

  &.ant-btn.ant-btn-primary:disabled {
    background-color:  var(--disableColor) !important;
    color:#505050 !important;
    border: none;
  }
}

.dynamic-btn-default{
  --borderColor: #C71C8E;
  --disableColor:rgba(0, 0, 0, 0.04);
  border: 1px solid var(--borderColor);
  color: var(--borderColor);

  &:hover,
  &:focus{
    border: 1px solid var(--borderColor);
    color: var(--borderColor);
  }

  &.ant-btn.ant-btn-default:disabled {
    background-color:  var(--disableColor) !important;
    color:#505050 !important;
  }
}

.add-button1 {
  height: 40px;
  width: 120px !important;
  border-radius: 10px;
  // float: right;

  svg {
    margin-right: 10px;
    font-size: 13px;
  }
}

.dynamic-btn-default-back{
  --borderColor: #C71C8E;
  --disableColor:rgba(0, 0, 0, 0.04);
  border: 1px solid var(--borderColor);
  color: var(--borderColor);
  border-radius: 10px;
  height: 40px;
  width: 100px !important;

  &:hover,
  &:focus{
    border: 1px solid var(--borderColor);
    color: var(--borderColor);
  }

  &.ant-btn.ant-btn-default:disabled {
    background-color:  var(--disableColor) !important;
    color:#505050 !important;
  }
}

.dynamic-radio {  
  --borderColor: #273896;
  .ant-radio-checked .ant-radio-inner {
    border-color: var(--borderColor) !important;   
  }
  &-wrapper:hover .ant-radio,
  &:hover,
  &-focused {
    .ant-radio-inner {
      border-color: var(--borderColor) !important;
    }
  }
  
  .ant-radio-inner {
      border: 1px solid var(--borderColor);
  }
  .ant-radio-inner:after {
      background: var(--borderColor) !important;
      border: 1px solid var(--borderColor) !important;
  }
  .ant-radio-input{
    border: 1px solid var(--borderColor) !important;
  }
}

.dynamic-pagination-checkbox {
  --bgColor:#273896;
  .ant-pagination-item-active{
     background: var(--bgColor);
   
     border:none;
   }
   .ant-pagination,.ant-pagination-mini,.ant-table-pagination,.ant-table-pagination-right,.ant-pagination-item{
     color: black;
   } 
   .ant-pagination-item-active{
     color: white;
   }
   .anticon,.anticon-double-right,.ant-pagination-item-link-icon{
     color:var(--bgColor);
     svg {
       color:var(--bgColor);
     }
   }
    .ant-checkbox {
     &-wrapper:hover .ant-checkbox,
     &:hover,
     &-focused {
         .ant-checkbox-inner {
             border-color: var(--bgColor);
         }
     }
     &-inner {
         border: 1px solid var(--bgColor);
         background-color: $white;
     }
     &-checked,
     &-indeterminate {
         .ant-checkbox-inner {
             background-color: var(--bgColor);
             border-color: var(--bgColor);
         }
     }
 }
   
 }
 
 .dynamic-pagination-checkbox1 {
  --bgColor:#273896;
  .ant-pagination-item-active{
     background: var(--bgColor);
     border:none;
   }
   .ant-pagination,.ant-pagination-mini,.ant-table-pagination,.ant-table-pagination-right,.ant-pagination-item{
     color: black;
   } 
   .ant-pagination-item-active{
     color: white;
   }
   .anticon,.anticon-double-right,.ant-pagination-item-link-icon{
     color:var(--bgColor);
     svg {
       color:var(--bgColor);
     }
   }
    .ant-checkbox {
     &-wrapper:hover .ant-checkbox,
     &:hover,
     &-focused {
         .ant-checkbox-inner {
             border-color: var(--bgColor);
         }
     }
     &-inner {
         border: 1px solid var(--bgColor);
         background-color: $white;
     }
     &-checked,
     &-indeterminate {
         .ant-checkbox-inner {
             background-color: var(--bgColor);
             border-color: var(--bgColor);
         }
     }
 }
   
 }

 .dynamic-tabs {
  --bgColor: #273896;
    .ant-tabs-tab-btn{
      color:#505050 !important;
    }
    .ant-tabs-nav-list {
      color: var(--bgColor);
  
      .ant-tabs-tab {
        color: var(--bgColor);
      }
    }
  
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--bgColor) !important;
      }
    }
  
    .ant-tabs-ink-bar {
      background-color: var(--bgColor);
    }
}

.dynamic-Step{
  --bgColor: #C71C8E;
  .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: var(--bgColor) !important;
}
}

.back-icon-btn {
  --btnColor:#4285F4;
  width: 100%;
  background-color: #fff;
  border-color: 1px solid var(--btnColor) ;
  color: var(--btnColor);

  &:hover,
  &:focus{
    border: 1px solid var(--btnColor);
    color: var(--btnColor);
  }

  svg {
    width:15px;
    height: 15px;
    margin: 0px 5px 0px 5px;
    
    circle,
    path {
      fill:var(--btnColor);  
    }
 }
}

.shareIcon {
  svg{
    color:black !important
  }
}

.bulk-uplopad-span {
  --bgColor:#C71C8E;
  color: var(--bgColor);
  display: flex;
  justify-content: center;
 
  svg {
    margin-top: -3px;
    circle,
    path {
      fill:var(--bgColor);  
    }
 }
    
  
}

.bulk-upload-border-primary-btn{
  --bgColor: #273896;
  color:var(--bgColor);

  &:hover,
  &:focus{
    border : 1px solid var(--bgColor);
    color: var(--bgColor);
  }

}

.bulk-upload-border-default-btn{
  --bgColor:#C71C8E;
  border : 1px solid var(--bgColor);
  color:var(--bgColor);
  width: 300px;
   padding: 5px;
   
  &:hover,
  &:focus{
    border : 1px solid var(--bgColor);
    color: var(--bgColor);
  }
}

.bulk-upload-text {
  --textColor:#C71C8E;
  color:var(--textColor);
}

.dynamic-icon {
  --fileIconColor:#273896;
  display: flex;
  gap:5px;
  margin-right:10px;
  svg {
    width: 25px;
    height: 25px;
    margin-bottom: 15px;
    circle,
    path {
      fill:var(--fileIconColor);           
    }
    .fill-white {
        fill: #fff;
    }
    .custom-stroke {
        stroke:var(--fileIconColor);
    }
 }
}

.border-file-icon {
  --fileIconColor:#273896;
  svg{
    font-size: 11px !important;
    path{
      stroke: var(--fileIconColor);
   }
}
}

.border-edit-icon {
  --borderColor:#273896;
  --width: 24px;
  border: none;
  svg{
    font-size: 11px !important;
    width: var(--width);
    height: var(--width);
    path{
      fill: var(--borderColor);
   }
}
}

.custome-btn-icon {
  --bgColor: #273896;
  --width: 24px;
  background-color: transparent;
  border: none;
  svg {
    width: var(--width);
    height: var(--width);
    path {
      stroke:var(--bgColor);
    }
    .path-fill {
      fill:var(--bgColor);
    }
  }    
}

.custome-btn-icon-download {
  
  --width: 24px;
  background-color: transparent;
  border: none;
  svg {
    width: var(--width);
    height: var(--width);
  }    
}

.custome-btn-icon-secondary {
  --bgColor: #C71C8E;
  --width: 24px;
  background-color: transparent;
  border: none;
  svg {
    width: var(--width);
    height: var(--width);
    path {
      stroke:var(--bgColor);
    }
    circle {
      fill:var(--bgColor);
    }
  }    
}

.custome-btn-icon-secondary-fill {
  --bgColor: #C71C8E;
  --width: 24px;
  background-color: transparent;
  border: none;
  svg {
    width: var(--width);
    height: var(--width);
    path {
      fill:var(--bgColor);
    }
    circle {
      fill:var(--bgColor);
    }
  }    
}



.custome-btn-icon-play {
  --bgColor: #273896;
  svg {
    width: 24px;
    height: 24px;
  }
  .custome-stroke {
    stroke:var(--bgColor);
  }
}

@media only screen and (max-width: 1096px) {
  .filter-bar {
    padding: 15px 0px;
  }  
}


.dynamic-btn-default-cancel{
  --borderColor: #C71C8E;
  --disableColor:rgba(0, 0, 0, 0.04);
  border: 1px solid var(--borderColor);
  color: var(--borderColor);
  border-radius: 10px;
  height: 40px;
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 20px;
  &:hover,
  &:focus{
    border: 1px solid var(--borderColor);
    color: var(--borderColor);
  }

  &.ant-btn.ant-btn-default:disabled {
    background-color:  var(--disableColor) !important;
    color:#505050 !important;
  }
}

.dynamic-btn-default-submit{
  --btnColor: #273896;
  --disableColor:rgba(0, 0, 0, 0.04);
  border-color: var(--btnColor) !important;
  background-color: var(--btnColor) !important;
  color:#fff !important;

  &.ant-btn.ant-btn-primary:disabled {
    background-color:  var(--disableColor) !important;
    color:#505050 !important;
    border: none;
  }
  height: 40px;
  width:100% !important;
  border-radius: 10px;
  // float: right;

  svg {
    margin-right: 10px;
    font-size: 13px;
  }
}