.qrcode-main-div {
  background-color: white;
  height: calc(100vh - 100px);
  border-radius: 8px;
  display: grid;
  justify-content: center;
  align-content: center;
  margin: 20px;
  .qrcode {
    gap: 10px;
    display: grid;
    overflow-x: auto;
    padding-block: 10px;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    .qrcode-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-btn {
        border: none;
        color: black;
        padding: 0px;
        svg {
          margin-right: 5px;
        }
      }
    }
    .qrcode-body {
      background: #f3f6fc;
      border-radius: 8px;
      padding: 20px;
      padding-top: 25px;
      gap: 25px;
      display: grid;
      .qrcode-error {
        min-width: 250px;
        min-height: 350px;
        color: red;
        max-width: 250px;
        text-align: center;
        overflow: auto;
      }
      .qrcode-body-name {
        gap: 10px;
        .qr-name-firstletter {
          border-radius: 50%;
          background: #ed217c;
          color: white;
          width: 40px;
          height: 40px;
          font-size: 18px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .qrcode-footer-buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      .btn-print {
        border: 1px solid #747775;
        border-radius: 20px;
        color: #0a57d4;
        height: 38px;
      }
      .btn-share-qr-code {
        border: none;
        background: #0a57d4;
        color: white;
        border-radius: 30px;
        height: 38px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
    .qrcode-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
  @media (max-width: 768px) {
    margin: 0px;
    height: calc(100vh - 82px);
  }
}

.instructions-main-div {
  background-color: white;
  height: calc(100vh - 100px);
  border-radius: 8px;
  display: grid;
  margin: 20px;
  .instructions-view {
    gap: 10px;
    display: grid;
    overflow-x: auto;
    padding-block: 10px;
    padding-inline: 30px;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    .inst-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-btn {
        border: none;
        color: black;
        padding: 0px;
        svg {
          margin-right: 5px;
        }
      }
      div {
        font-size: 25px;
        font-weight: bold;
      }
    }
    .inst-body {
      padding-inline: 20px;
      .inst-header {
        font-weight: 500;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 5px;
      }
      .inst-text {
        margin-left: 20px;
      }
    }
    .inst-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
    }
  }

  @media (max-width: 768px) {
    margin: 0px;
    height: calc(100vh - 82px);
    .instructions-view {
      padding-inline: 10px;
      .inst-body {
        padding-inline: 3px;
      }
    }
  }
}
